
  import { Component, Vue } from 'vue-property-decorator';
  import { FETCH_TAGS } from '@/store/tags';
  import InsightEdit from '@/components/InsightEdit.vue';
  import { DELETE_INSIGHT, FETCH_INSIGHT_ITEM, UPDATE_INSIGHT } from '@/store/insights';

  @Component({
    components: { InsightEdit }
  })
  export default class InsightsDetails extends Vue {
    beforeMount() {
      this.$store.dispatch(FETCH_TAGS);
      this.$store.dispatch(FETCH_INSIGHT_ITEM, this.$route.params.slug);
    }

    public save() {
      if (!(this.$refs.insightEdit as InsightEdit).checkValidation()) {
        return;
      }

      this.$store.dispatch(UPDATE_INSIGHT)
        .then(() => this.$router.push({ name: 'app.insights.list' }));
    }

    public remove() {
      this.$store.dispatch(DELETE_INSIGHT)
        .then(() => this.$router.push({ name: 'app.insights.list' }));
    }
  }
